import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}\s*$/;

export default function ContactUs() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handleError = (errors) => {};

  const onSubmit = async (formData) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/contact-brilliant-cleaning`, formData);
      if (response.status === 200) {
        toast.success('Message sent successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        reset();
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error("I'm sorry, message wasn't sent. Please try again.", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const registerOptions = {
    name: {
      required: 'Name is required',
      minLength: {
        value: 3,
        message: 'Name should be at least 3 characters long',
      },
      maxLength: {
        value: 24,
        message: 'Name should be max 24 characters long',
      },
    },
    email: {
      required: 'Email is required',
      pattern: { value: EMAIL_REGEX, message: 'Email is invalid' },
    },
    subject: {
      required: 'Subject is required',
      minLength: {
        value: 4,
        message: 'Subject should be at least 4 characters long',
      },
      maxLength: {
        value: 100,
        message: 'Subject should be max 100 characters long',
      },
    },
    message: {
      required: 'Message is required',
      minLength: {
        value: 10,
        message: 'Message should be at least 10 characters long',
      },
      maxLength: {
        value: 600,
        message: 'Message should be max 600 characters long',
      },
    },
  };

  return (
    <motion.section
      className="section_all"
      id="contact"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <motion.div
              className="section_title_all text-center"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="section_icons">
                <motion.i
                  className="mbri-bookmark"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                ></motion.i>
              </div>
              <h3 className="mt-3">
                Contact <span className="text_custom"> Us </span>
              </h3>
            </motion.div>
          </div>
        </div>

        <div className="row mt-5">
          <motion.div
            className="col-lg-4"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <div className="contact_info_content text-center mt-3">
              <div className="minimal_contact_icon">
                <i className="mbri-mobile2 text_custom h2"></i>
              </div>
              <div className="mt-2">
                <p className="mb-0 con_head">Call Us Now</p>
                <p className="text-muted mb-0">+773-319-8087</p>
                <p className="text-muted mb-0">+847-630-2853</p>
              </div>
            </div>

            <div className="contact_info_content text-center mt-3">
              <div className="minimal_contact_icon">
                <i className="mbri-letter text_custom h2"></i>
              </div>
              <div className="mt-2">
                <p className="mb-0 con_head">Mail With Us</p>
                <p className="text-muted">brilliantservice@yahoo.com</p>
              </div>
            </div>

            <div className="contact_info_content text-center mt-3">
              <div className="minimal_contact_icon">
                <i className="mbri-pin text_custom h2"></i>
              </div>
              <div className="mt-2">
                <p className="mb-0 con_head">Location</p>
                <p className="text-muted">
                  Chicago, IL
                  <br />{' '}
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="col-lg-8"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            <div className="landing_form_custom business_form_custom bg-white mt-3">
              <form onSubmit={handleSubmit(onSubmit, handleError)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        className="form-control"
                        placeholder="Your Name *"
                        name="name"
                        type="text"
                        {...register('name', registerOptions.name)}
                      />
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.name && errors.name.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        className="form-control"
                        placeholder="Your Email *"
                        type="email"
                        name="email"
                        {...register('email', registerOptions.email)}
                      />
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.email && errors.email.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <input
                        className="form-control"
                        placeholder="Your Subject.."
                        type="text"
                        name="subject"
                        {...register('subject', registerOptions.subject)}
                      />
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.subject && errors.subject.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <textarea
                        rows="6"
                        className="form-control"
                        placeholder="Your message..."
                        type="text"
                        name="message"
                        {...register('message', registerOptions.message)}
                      ></textarea>
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.message && errors.message.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12 text-center">
                    <input type="submit" className="btn btn_custom" value="Send Message" />
                  </div>
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </div>
      {/* ToastContainer to display toast notifications */}
      <ToastContainer />
    </motion.section>
  );
}
