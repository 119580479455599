import React from 'react';
import { motion } from 'framer-motion';


export default function Home() {
  return (
    <section className="section_all back-img home-page-height " id="home">
      <div className="container">
        <motion.div
          className="bg_overlay_cover_on"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <div className="box-set ">
          <motion.div
              className="box box-1"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <img src="/bri.png" alt="" className="star1" />
            </motion.div>
            <motion.div
              className="box box-2"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              <img src="/brilant.png" alt="" className="star2" />
            </motion.div>
            <motion.div
              className="box box-3"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
            >
              <img src="/bri.png" alt="" className="star3" />
            </motion.div>
          </div>
        </motion.div>
        <div className="row vertical_content_manage container">
          <div className="row">
            <div className="col-lg-12">
            <motion.h1
                className="text-white text-brilliant"
                style={{ fontSize: '430%', marginTop: '20%' }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.5 }}
              >                Brilliant Service
              </motion.h1>
              <div className="mt-3">
              <motion.h4
                  style={{ fontSize: '180%' }}
                  className="text-white mb-0 pt-3"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 1 }}
                >
                  Professional Cleaning and Covid Certified Disinfection.
                </motion.h4>
                <div className="home_text_details">
                  <h4
                    style={{
                      paddingTop: '10%',
                      color: 'orange',
                      fontFamily: 'Papyrus,fantasy ',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                    className=" pep home_subtitle mt-5 mb-0 text-center"
                  >
                    Do you want to have a vacation at your home???
                  </h4>
                  <h4
                    style={{
                      color: 'orange',
                      fontFamily: 'Papyrus,fantasy ',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                    className="pep home_subtitle mt-2 mb-0 text-center"
                  >
                    It's possible !!!
                  </h4>
                  <h4
                    style={{ color: 'orange', fontFamily: 'Papyrus,fantasy ', fontStyle: 'italic', fontWeight: 'bold' }}
                    className=" pep home_subtitle mt-2 mb-0 text-center"
                  >
                    We will clean <span className="fr">4</span> you.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
          <motion.div
              className="mt-3"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.5, delay: 2 }}
            >
              <img
                style={{ height: '260px', width: '270px' }}
                src="Brilliant-Logo.png"
                alt=""
                className="img-fluid mx-auto d-block"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
