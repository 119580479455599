import React from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';

export default function Navbar() {
  return (
    <motion.nav
      className="navbar navbar-expand-lg fixed-top custom_nav_menu sticky"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="container">
        <a className="navbar-brand logo" href="/">
          <img src="Brilliant-Logo.png" alt="" className="img-fluid " />
        </a>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item active">
              <Link
                to="home"
                smooth={true}
                duration={500}
                className="nav-link"
                offset={-70} // Adjusts scroll position to account for fixed navbar height
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="aboutUs" smooth={true} duration={500} className="nav-link" offset={-70}>
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="about" smooth={true} duration={500} className="nav-link" offset={-70}>
                Cleaning
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="desinfection"
                smooth={true}
                duration={500}
                className="nav-link"
                offset={-70}
              >
                Disinfection
              </Link>
            </li>
            <li className="nav-item">
              <Link to="client" smooth={true} duration={500} className="nav-link" offset={-70}>
                Clients
              </Link>
            </li>
            <li className="nav-item">
              <Link to="pricing" smooth={true} duration={500} className="nav-link" offset={-70}>
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link to="contact" smooth={true} duration={500} className="nav-link" offset={-70}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </motion.nav>
  );
}
