import React from 'react';
import { motion } from 'framer-motion';
import DisinfectionItem from './DisinfectionItem';

export default function Disinfection() {
  const items = [
    "Logo/GBAC.jpeg",
    "/Logo/image3.jpg",
    "/Logo/image0.jpg",
    "/Logo/IMG_5731.jpg",
    "/Logo/IMG_6413.jpg",
    "/Logo/image4.jpg",
    "/Logo/image2.jpg",
    "/Logo/disen2.jpg",
  ];

  return (
    <section className="section_all text-center toto" id="desinfection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <motion.div
              className="section_title_all text-center"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <h3 className="mt-3">
                <span className="text_custom">Disinfection</span>
              </h3>
              <motion.p
                className="section_subtitle mx-auto text-muted"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Certified commercial and residential disinfection service to eliminate contamination from viruses.
                Certified and trained with an emphasis on SARS-Co V-2/COVID. We are using EPA register products to kill
                germs and reduce risk of spreading infection. The product meets the EPA's emerging pathogens
                requirements, showing efficacy against enveloped and nonenveloped viruses.
              </motion.p>
            </motion.div>
          </div>
        </div>

        <motion.div
          className="row mt-5"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              opacity: 0,
              y: 50,
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          {items.map((img, index) => (
            <DisinfectionItem key={index} img={img} index={index} />
          ))}
        </motion.div>
      </div>
    </section>
  );
}
