import React from 'react';
import { motion } from 'framer-motion';

export default function DisinfectionItem(props) {
  return (
    <motion.div
      className="col-lg-3 work_item"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: props.index * 0.2 }}
    >
      <div className="p-6 bg-light rounded">
        <a className="img-zoom" href={props.img}>
          <div className="work_box">
            <div className="work_img">
              <img
                style={{ height: '200px' }}
                src={props.img}
                className="img-fluid mx-auto d-block rounded"
                alt={props.alt}
              />
            </div>
          </div>
        </a>
      </div>
    </motion.div>
  );
}
