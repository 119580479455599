import React from 'react';
import { motion } from 'framer-motion';

export default function Services() {
  return (
    <section className="section_all toto" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <motion.div
              className="section_title_all text-center"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="section_icons">
                <motion.i
                  className="mbri-target"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.5 }}
                ></motion.i>
              </div>
              <motion.h3
                className="mt-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
              >
                What Type of Cleaning we do <span className="text_custom"> For you </span>
              </motion.h3>
            </motion.div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6">
            <motion.div
              className="about_content_box_all text-center mt-3"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <div className="about_detail">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 1.2 }}
                >
                  <p className="text-dark text-capitalize mt-3">Disinfection</p>
                  <p className="text-dark text-capitalize mt-3">Residential</p>
                  <p className="text-dark text-capitalize mt-3">Office</p>
                  <p className="text-dark text-capitalize mt-3">Commercial</p>
                  <p className="text-dark text-capitalize mt-3">After construction</p>
                  <p className="text-dark text-capitalize mt-3">Move In/Out</p>
                  <p className="text-dark text-capitalize mt-3">Workshop</p>
                </motion.div>
              </div>
            </motion.div>
          </div>

          <div className="col-lg-6">
            <motion.div
              className="about_content_box_all text-center mt-3"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 1.3 }}
            >
              <div className="about_detail">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 1.5 }}
                >
                  <p className="text-dark text-capitalize mt-3">Daily</p>
                  <p className="text-dark text-capitalize mt-3">Weekly</p>
                  <p className="text-dark text-capitalize mt-3">Every other week</p>
                  <p className="text-dark text-capitalize mt-3">Monthly</p>
                  <p className="text-dark text-capitalize mt-3">One time</p>
                  <p className="text-dark text-capitalize mt-3">Holiday</p>
                  <p className="text-dark text-capitalize mt-3">Occasional/Event</p>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
