import React from 'react';
import { motion } from 'framer-motion';

export default function Pricing() {
  return (
    <section style={{ paddingTop: '10%', paddingBottom: '10%' }} className="section_all bg-light toto" id="pricing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <motion.div
              className="section_title_all text-center"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <motion.div
                className="section_icons"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1, delay: 0.3 }}
              >
                <i className="mbri-like"></i>
              </motion.div>
              <motion.h3
                className="mt-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Our <span className="text_custom"> Pricing </span>
              </motion.h3>
              <motion.p
                className="section_subtitle mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.7 }}
              >
                Estimates are always free for our prospective clients.
              </motion.p>
              <motion.p
                className="section_subtitle mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.9 }}
              >
                Give us a call, text or email us.
              </motion.p>
              <motion.p
                className="section_subtitle mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1.1 }}
              >
                We would like to see your place and give you a quote. It's always free! We will have a chance to talk
                about your needs. Our prices are reasonable and definitely we can fit in your budget. Same day cleaning
                service for residential, construction, and commercial businesses are also available.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
