import React from 'react';
import Slider from 'react-slick';
import ReviewsItems from './ReviewsItems';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function Reviews() {
  const settings = {
    dots: true, // Enable dots navigation
    infinite: true, // Enable infinite scrolling
    speed: 600, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed (in milliseconds)
    arrows: false, // Hide navigation arrows

    
  };

  return (
    // <section className="section_all bg_testimonial_business" id="client">
    <section className="section_all toto" id="client">
      <div style={{ height: '600px' }} className="bg_overlay_cover_on"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div id="owl-demo" className="owl-carousel"> */}
            <Slider {...settings}>
              <ReviewsItems
                paragraph={`
                  "I'd give more stars if I could. I've been using Brilliant Cleaning for several years.
                  They clean the halls of the building I used to live in and used to clean my old unit
                  there and now clean my current home. They are VERY responsive via text, flexible with schedules,
                  and show up on time every time. I've recommended them to several friends who have also used
                  Brilliant Cleaning for some time now. I always recommend them to anyone needing a cleaner.
                  I use them for regular bi-weekly cleanings but have used them 3 times for move out cleanings
                  also and both provide a very great value and excellent service. They do such a great job.
                  I have nothing negative to say, Brilliant Cleaning is everything I could ask for out of a cleaning service."
                `}
                name="Doug B."
                type="Client"
              />
              <ReviewsItems
                paragraph={`
                  "I just can not say enough good things about my experience with this company.
                  I have been working with them for about 7 years and they are just amazing.
                  I really appreciate honest and trustworthy people and these are the kind of people that they work with.
                  I have been working with Cecilia since 3 years ago roughly and she's just awesome.
                  She has great attitude and my house looks just perfect and neat.
                  I highly recommend this service and please give them the opportunity to work with you!
                  Managers are awesome, they always accommodate date/time in case I need to.
                  It's been a pleasure working with them all these years."
                `}
                name="Wendy C."
                type="Client"
              />

              <ReviewsItems
                paragraph={`
                  "I can't say enough about Brilliant Cleaning - they are top notch.
                  My wife and I had a service some years ago that was dissolved so we were in need of another service.
                  We tried several and had a horrible experience.
                  Then we were referred to Brilliant by a co-worker/friend and have been thrilled ever since.
                  They are not only fair priced but also flexible as can be and the product is the best....a very clean home!
                  We consider the person that cleans our home a friend - she has access to our home as they are completely trustworthy
                  and we enjoy catching up when we are home when she is here.  Great people, great company and a great service!"
                `}
                name="Ed P."
                type="Client"
              />

              <ReviewsItems
                paragraph={`
                "I have been using Brilliant cleaning services for over 10 years and I am very happy with their service!
                Iwona the owner is very accommodating to any of your needs. They take time and get the job done! I highly recommend them!"
                `}
                name="Julie G."
                type="Client"
              />

              <ReviewsItems
                paragraph={`
                  "I've been using Brilliant Cleaning for a number of months now. They hit EVERYWHERE,
                  are super responsive (I use them on a regular basis), and have a great price.
                  1) Wonderful service. They hit everything, and go through an initial co silt if you are
                  looking to do a regular cleaning schedule with them.
                  2) Easy to schedule. Very flexible.
                  3) Great price.
                  6 out of 5 stars."                
                `}
                name="Mike R."
                type="Client"
              />
              </Slider>
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
